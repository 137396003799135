import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { statistics } from "@/@types/statistics";

import PieChart from "@/components/charts/PieChart/PieChart.vue";
import BarChart from "@/components/charts/BarChart/BarChart.vue";
import { EChartsOption } from "echarts";
import { formatWeight } from "@/utils/formatter";
import { sum } from "@/utils/extensions";

@Component({ components: { PieChart, BarChart } })
export default class Statistics extends Vue {
    @Prop({ required: true, default: () => [] })
    features: geoserver.feature<wfsDataLayer.properties, wfsDataLayer.geometry>[]

    @Prop({ required: true, default: false })
    loading: boolean

    statistics: statistics.data = null;
    created() {
        this.makeChart(this.features);
    }

    @Watch("features")
    onFeaturesChange() {
        this.statistics = null;
        this.$nextTick(() => {
            this.makeChart(this.features);
        })
    }

    formatWeight(value: any, c?: number, d?: string, t?: string) {
        return formatWeight(value, c, d, t);
    }

    public makeChart(values: geoserver.feature<wfsDataLayer.properties, wfsDataLayer.geometry>[]) {
        const _shape_area: number | null = sum(values, m => m.properties.Shape_Area);

        const _population: number = sum(values, m => m.properties.population);
        const _children: number = sum(values, m => m.properties.children);
        const _elderly: number = sum(values, m => m.properties.elderly);

        const _g_pop: number = sum(values, m => ((m.properties.g_pop || 0) * (m.properties.population || 0))) / _population;
        const _g_children: number = sum(values, m => ((m.properties.g_children || 0) * (m.properties.children || 0))) / _children;
        const _g_elderly: number = sum(values, m => ((m.properties.g_elderly || 0) * (m.properties.elderly || 0))) / _elderly;
        const _t_pop: number = sum(values, m => ((m.properties.t_pop || 0) * (m.properties.population || 0))) / _population;
        const _t_children: number = sum(values, m => ((m.properties.t_children || 0) * (m.properties.children || 0))) / _children;
        const _t_elderly: number = sum(values, m => ((m.properties.t_elderly || 0) * (m.properties.elderly || 0))) / _elderly;


        const _g_density = (sum(values, m => (m.properties.g_density || 0) * (m.properties.Shape_Area || 0) / 100)) / sum(values, m => m.properties.Shape_Area / 100);
        const _g_on_nog = (sum(values, m => (m.properties.g_density || 0) * (m.properties.Shape_Area || 0) / 100)) / (_shape_area - sum(values, m => (m.properties.g_density || 0) * (m.properties.Shape_Area || 0) / 100));

        const _slo_mean: number = sum(values, m => (m.properties.slo_mean || 0) * ((m.properties.g_density || 0) * (m.properties.Shape_Area || 0) / 100)) / sum(values, m => (m.properties.g_density) * (m.properties.Shape_Area || 0) / 100)
        const _slo_min: number = Math.min(...values.map(m => m.properties.slo_min));
        const _slo_max: number = Math.max(...values.map(m => m.properties.slo_max));

        this.statistics = {
            hasPopulation: sum(values, m => m.properties.population) > 0,
            population: {
                legend: { top: '0px', left: 'center' },
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '80%'],
                        avoidLabelOverlap: false,
                        label: { show: false, position: 'center' },
                        emphasis: {
                            label: { show: true, fontSize: 24, fontWeight: 'bold' }
                        },
                        labelLine: { show: false },
                        data: [
                            { value: _children, name: 'Children' },
                            { value: _elderly, name: 'Elderly' },
                            { value: (_population - _children - _elderly), name: 'Others' }
                        ]
                    }
                ]
            } as EChartsOption,
            shape_area: _shape_area,
            greenAndWoodenUrban: {
                tooltip: {
                    trigger: 'axis',
                    valueFormatter: (value: number) => {
                        return `${!!value ? formatWeight(value, 0) : ""} m²`;
                    }
                },
                legend: { top: '0px', left: 'center' },
                xAxis: { type: 'category', data: ['Population', 'Children', 'Elderly'] },
                yAxis: {
                    visible: false, type: 'value', boundaryGap: [0, 0.01],
                    axisLabel: {
                        show: true,
                        formatter: (value) => {
                            return `${!!value ? formatWeight(value, 0) : ""} m²`;
                        }
                    }
                },
                series: [{
                    name: 'Green areas', type: 'bar',
                    data: [_g_pop, _g_children, _g_elderly]
                },
                {
                    name: 'Wooded areas', type: 'bar',
                    data: [_t_pop, _t_children, _t_elderly]
                }]
            } as EChartsOption,
            g_density: {
                tooltip: {
                    valueFormatter: (value) => {
                        return `${!!value ? formatWeight(value, 0) : ""}%`;
                    }
                },
                legend: { top: '0px', left: 'center' },
                series: [
                    {
                        type: 'pie',
                        radius: ['0%', '76%'],
                        avoidLabelOverlap: false,
                        label: { show: false, position: 'center' },
                        labelLine: { show: false },
                        data: [
                            { value: (100 - _g_density), name: 'No Green' },
                            { value: _g_density, name: 'Green' }
                        ]
                    }
                ]
            } as EChartsOption,
            g_on_nog: _g_on_nog,
            slo_mean: _slo_mean,
            slo_min: _slo_min,
            slo_max: _slo_max,

        }
    }
}

