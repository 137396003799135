import { Vue, Component, Prop } from "vue-property-decorator";

import { Map } from "ol";
import { Extent } from "ol/extent";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";

import TocItem from "./toc-item.vue";
import { OlSwipeControl } from "@/views/MapHome/components/swipe/OlSwipeControl";

@Component({ components: { TocItem } })
export default class Toc extends Vue {
    @Prop({ required: true, default: () => [] })
    value: (TileLayer<TileWMS>)[]

    @Prop({ required: true })
    map: Map    
    
    @Prop({ default: null, required: false })
    tab?: string

    olSwipe: OlSwipeControl = null;
    splitedLayersIds: number[] = [];
    onSplitView(index: number) {
        const splitedLayerIdx = this.splitedLayersIds.indexOf(index);
        if (splitedLayerIdx >= 0) {
            this.value[index].setZIndex(index);
            this.splitedLayersIds.splice(splitedLayerIdx, 1);

        } else {
            this.splitedLayersIds.push(index);
            this.value[index].setZIndex(this.value.length + this.splitedLayersIds.length);
        }

        if (this.olSwipe) {
            this.olSwipe.remove();
            this.map.removeControl(this.olSwipe);
            this.olSwipe = null;
        }

        if (this.splitedLayersIds.length > 0) {
            if (!this.map.getControls().getArray().includes(this.olSwipe)) {
                this.olSwipe = new OlSwipeControl();
                this.map.addControl(this.olSwipe)
            }
            this.olSwipe.open();
            if (this.splitedLayersIds.length > 0) {
                const rightLayer = this.value[this.splitedLayersIds[1]];
                this.olSwipe.setRightLayer(rightLayer);
            }
            const leftLayer = this.value[this.splitedLayersIds[0]];
            this.olSwipe.setLeftLayer(leftLayer);
        }
    }

    onZoomTo(extent: Extent) {
        if (!extent) {
            extent =[784805.1273973236, 5558937.973450844, 1250459.5037106173, 5803230.715850267]

        }
        this.map.getView().fit(extent)
    }
}