import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {

  }
})
export default class Slider extends Vue {
  @Prop({ required: false, default: 0 })
  min: number
  @Prop({ required: false, default: 100 })
  max: number
  @Prop({ required: false, default: 1 })
  step: number

  @Prop({ required: false, default: 100 })
  value: number

  selected: number = 0
  mounted() {
    this.selected = this.value
  }
  @Watch("value")
  changeInistial() {
    this.selected = this.value
  }
  @Watch("selected")
  change() {
    this.$emit("change", this.selected)
  }
}
