import settings from "@/settings";
import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/components/modal/modal.vue";
import { authService } from "@/services/authService";

@Component({ components: { Modal } })
export default class MainHeader extends Vue {
    displayProductInfo: boolean = false;
    get product(): { title: string, description: string, metadata: string, tableDates: string } {
        return settings.product;
    }

    downloadMetadata() {
        window.open(this.product.metadata, "_blank");
        this.displayProductInfo = !this.displayProductInfo;
    }

    async logout() {
        // await authService.logout();
        this.$msal.signOut()
    }

    get user() {
        let user = null;
        if (this.$msal.isAuthenticated()) {
            user = {
                ...this.$msal.data.user,
                profile: {}
            }
            if (this.$msal.data.graph && this.$msal.data.graph.profile) {
                user.profile = this.$msal.graph.data.profile
            }
        }
        return user;
    }
}