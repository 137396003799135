import settings from "@/settings";
import axios from "axios";

class GeoServerService {
    async featureServiceLayerData(url: string, token: string = null): Promise<geoserver.featureService<wfsDataLayer.properties, wfsDataLayer.geometry>> {
        const query = await axios.get(url, {
            headers: {
                // Authorization: `Basic ${token}`,
                "Access-Control-Allow-Origin": `*`
            }
        })

        if (query.status === 200) {
            return (query?.data as geoserver.featureService<wfsDataLayer.properties, wfsDataLayer.geometry>) ?? null;
        } else {
            console.error(query.data)
            return null;
        }
    }

    async download(url: string, token: string = null): Promise<string> {
        const query = await axios.get(url, {
            headers: {
                // Authorization: `Basic ${token}`,
                "Access-Control-Allow-Origin": `*`
            },
            responseType: 'arraybuffer'
        })

        if (!query || query.status !== 200) {
            console.error(query);
            return null;
        }

        const contentType = `${query.headers["content-type"] || 'application/octet-stream'}`;
        const blob = new Blob([query.data], { type: contentType });
        return window.URL.createObjectURL(blob)
    }

    async downloadPluginWPS(params: geoserver.params, token: string = null, downloadType: string = 'image/tiff'): Promise<string> {

        const replacements = { ...params, downloadType }//, token };

        const body = settings.wpsPlugin.xmlConfig.replace(
            /{(\w+)}/gi,
            (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
                replacements[placeholderWithoutDelimiters] || placeholderWithDelimiters
        );

        const query = await axios.post(settings.wpsPlugin.serviceUrl, body, {
            headers: {
                'Content-Type': 'text/xml',
                // Authorization: `Basic ${token}`,
                "Access-Control-Allow-Origin": `*`
            },
            responseType: 'arraybuffer'
        })

        if (!query || query.status !== 200) {
            console.error(query);
            return null;
        }

        const contentType = `${query.headers["content-type"] || 'application/octet-stream'}`;
        const blob = new Blob([query.data], { type: contentType });
        return window.URL.createObjectURL(blob)
    }
}
export const geoServerService = new GeoServerService();