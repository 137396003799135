import axios from 'axios';
import * as echarts from 'echarts';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ResizeMixin from '@/mixins/resize'
import { mergeDeep } from '@/utils/mergeDeep';
import { formatWeight } from '@/utils/formatter';

type EChartsOption = echarts.EChartsOption;

@Component
export default class PieChart extends mixins(ResizeMixin) {
    @Prop({ required: true }) private options: EChartsOption;
    @Prop({ default: 'chart-container' }) private className!: string;
    @Prop({ default: 'pieChart' }) private prefixId!: string;
    @Prop({ default: '100%' }) private width!: string;
    @Prop({ default: '100%' }) private height!: string;

    get id(){
        return `${this.prefixId}__${Math.random()*10000000}`
    }

    async created() {
        const options = {
            backgroundColor: this.options.backgroundColor ?? '#fff',
            tooltip: {
                position: 'inside',
                trigger: 'item',
                backgroundColor: 'rgba(255,255,255,0.9)',
                padding: [10, 15],
                axisPointer: { // Use axis to trigger tooltip
                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                confine: true,
                textStyle: {
                    fontSize: 12,
                    fontFamily: 'Lato, sans-serif'
                },
                valueFormatter: (value) => {
                    return `${!!value ? formatWeight(value, 0) : ""}`;
                }
            },
            animationEasing: 'elasticOut',
            animationEasingUpdate: 'elasticOut',
            animationDelay: (idx: number) => { return idx * 20; },
            animationDelayUpdate: (idx: number) => { return idx * 20; },
            series: [],
        } as EChartsOption;

        mergeDeep(options, this.options);

        this.$nextTick(() => {
            this.initChart(options);
        });
    }

    beforeDestroy() {
        if (!this.chart) {
            return;
        }
        this.chart.dispose();
        this.chart = null;
    }

    private initChart(options: EChartsOption) {
        this.chart = echarts.init(document.getElementById(this.id) as HTMLDivElement, null, {
            renderer: 'svg',
            useDirtyRect: false
        });
        this.chart.setOption(options);
    }
}