import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Feature, Map, MapBrowserEvent, Overlay, Tile, View } from 'ol';

import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import XYZ from 'ol/source/XYZ'

import settings from "@/settings";

import Toc from "../toc/toc.vue"
import Statistics from "../statistics/statistics.vue"

import VectorSource from "ol/source/Vector";
import { bbox } from "ol/loadingstrategy";
import GeoJSON from 'ol/format/GeoJSON.js';
import { Geometry } from "ol/geom";
import { toStringHDMS } from "ol/coordinate";
import { toLonLat } from "ol/proj";
import { ObjectEvent } from "ol/Object";
import { geoServerService } from "@/services/geoServerService";
import { debounce } from "@/utils/debounce";
import LayerGroup from "ol/layer/Group";
import { extend } from "ol/extent";

@Component({ components: { Toc, Statistics } })
export default class Maps extends Vue {
    @Prop({ default: null, required: false })
    layerId?: string

    @Prop({ default: null, required: false })
    tab?: string

    projection: string = 'EPSG:3857';
    map: Map = null;
    view: View = null;
    popup: Overlay = null;
    popupContent: string = "";

    created() {
        this.wmsLayers = this.wmsLayersFromSetting;
    }

    mounted() {
        this.view = new View({
            zoom: 9,
            projection: this.projection,
            center: [1014172.862976476, 5697560.720192192],
            constrainResolution: true
        });

        this.popup = new Overlay({
            element: document.getElementById('popup'),
            autoPan: {
                animation: {
                    duration: 250,
                },
            },
        });

        this.map = new Map({
            target: this.$refs.maproot as HTMLDivElement,
            layers: [this.baseMapLayer, ...this.wmsLayers],
            overlays: [this.popup],
            // the map view will initially show the whole world
            view: this.view
        });


        this.map.on('singleclick', this.onClick);
        this.map.getView().on('change:center', async (event: ObjectEvent) => {
            if (this.layerDataIsVisible) {
                await this.onChangeExtent(this);
            }
        });

        this.map.once('postrender', (event) => {
            if (this.layerDataIsVisible) {
                this.$nextTick(async () => {
                    await this.reloadLayerData(this);
                })
            }
        });
    }

    // private privateToken: string = localStorage.getItem("auth_code");
    // get token(): string {
    //     return this.privateToken
    // }
    // public set token(v: string) {
    //     this.privateToken = v;
    // }

    get baseMapLayer() {
        return new TileLayer({
            source: new XYZ({
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?token=AAPKff324c6263df44f8bf2c1cd76790918a5NoXXYZqVm50rGBli4GV4laaB_CGfqcOcB0wztSrdQDb_o4dnZdZ1IAVAZaENJB5',
                attributions: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            }),
            zIndex: 0
        });
    }

    get wmsLayersFromSetting() {
        return settings.wmsLayers.map((wmsLayer, index) => this.generateTileLayerWMS(wmsLayer, ((settings.wmsLayers.length - index) + 1)))
    }

    privateLayersWMS: TileLayer<TileWMS>[] = [];
    get wmsLayers(): TileLayer<TileWMS>[] {
        return this.privateLayersWMS;
    }
    set wmsLayers(value: TileLayer<TileWMS>[]) {
        this.privateLayersWMS = value;
    }


    get wfsLayerVectorSource() {
        let baseUrl = `${settings.geoServerUrl}/wfs?request=GetFeature&typename=${settings.wfsLayer.workspace}:${settings.wfsLayer.layer}&outputFormat=application/json`;
        const vectorSource = new VectorSource({
            format: new GeoJSON({
                dataProjection: this.projection
            }),
            loader: (extent, resolution, projection, success, failure) => {
                const proj = projection.getCode();
                let url = `${baseUrl}&srsname=${proj}&bbox=${extent.join(',')},${proj}`;

                const xhr = new XMLHttpRequest();
                xhr.open('GET', url);
                // xhr.setRequestHeader("Authorization", `Basic ${this.token}`);
                xhr.setRequestHeader("Access-Control-Allow-Origin", `*`);

                const onError = () => {
                    vectorSource.removeLoadedExtent(extent);
                    failure();
                }
                xhr.onerror = onError;

                xhr.onload = () => {
                    if (xhr.status == 200) {
                        const features = vectorSource.getFormat().readFeatures(xhr.responseText) as Feature<Geometry>[];
                        vectorSource.addFeatures(features);
                        success(features);
                    } else {
                        onError();
                    }
                }
                xhr.send();
            },
            strategy: bbox,
        });
        return vectorSource;

    }


    layerDataIsVisible: boolean = false;
    visibleLayers: TileLayer<TileWMS>[] = [];
    toggleVisibleLayer(evt: ObjectEvent) {
        const layer = evt.target as TileLayer<TileWMS>;
        const visible = layer.isVisible(this.view);
        const idx = this.visibleLayers.indexOf(layer);
        if (visible && idx <= 0) {
            this.visibleLayers.push(layer);
        } else {
            if (!visible && idx >= 0) {
                this.visibleLayers.splice(idx, 1);
            }
        }

        if (!this.visibleLayers || this.visibleLayers.length <= 0) {
            this.layerDataIsVisible = false;
        } else {
            const wfsLayer = settings.wfsLayer;
            this.layerDataIsVisible = this.visibleLayers.map(m => m.getSource()?.getParams() as geoserver.params)
                .filter(f => f.LAYERS === wfsLayer.layer && f.WORKSPACE === wfsLayer.workspace).length > 0

            if (this.layerDataIsVisible) {
                this.$nextTick(async () => {
                    await this.onChangeExtent(this);
                })
            }
        }

        this.onClosePopup()
    }

    onChangeStyle(el: { params: geoserver.params, style: { value: string, label: string } }) {
        this.onClosePopup()

        const layerStyleIdx: number = this.wmsLayers.findIndex(l => {
            const params = l.getSource()?.getParams() as geoserver.params;
            if (params && params.WORKSPACE === el.params.WORKSPACE && params.LAYERS === el.params.LAYERS && params.VERSION === el.params.VERSION) {
                return l;
            }
        });

        if (layerStyleIdx >= 0) {
            const current = this.wmsLayers[layerStyleIdx];
            this.map.removeLayer(current)
            const currentParams = current.getSource()?.getParams() as geoserver.params;
            const setting = settings.wmsLayers.find(f => f.layer === currentParams.LAYERS && f.workspace === currentParams.WORKSPACE && f.version === currentParams.VERSION);
            const zIndex = current.getZIndex();
            const isVisibleByDesign = current.isVisible(this.view);
            const layer = this.generateTileLayerWMS(setting, zIndex, el.style.value, isVisibleByDesign);
            this.wmsLayers.splice(layerStyleIdx, 1, layer)
            this.map.addLayer(layer)

            const visibilityLayerIdx: number = this.visibleLayers.findIndex(l => {
                const params = l.getSource()?.getParams() as geoserver.params;
                if (params && params.WORKSPACE === el.params.WORKSPACE && params.LAYERS === el.params.LAYERS && params.VERSION === el.params.VERSION) {
                    return l;
                }
            });

            if (visibilityLayerIdx >= 0) {
                this.visibleLayers.splice(visibilityLayerIdx, 1)
            }

            if (isVisibleByDesign) {
                this.visibleLayers.push(layer);
            }

            this.layerDataIsVisible = this.visibleLayers.map(m => m.getSource()?.getParams() as geoserver.params)
                .filter(f => f.LAYERS === el.params.LAYERS && f.WORKSPACE === el.params.WORKSPACE).length > 0
        }
    }

    async onClick(evt: MapBrowserEvent<any>) {
        const coordinate = evt.coordinate;
        const hdms = toStringHDMS(toLonLat(coordinate));

        this.popupContent = `<div class="ol-popup-title"><i>${hdms}</i></div>`;

        this.popupContent += `<div class="ol-popup-content">`;
        for (const l of [...this.wmsLayers].sort((a, b) => a.getZIndex() > b.getZIndex() ? -1 : 1)) {
            if (l.isVisible(this.view)) {
                const viewResolution = /** @type {number} */ (this.view.getResolution());
                const url = l.getSource().getFeatureInfoUrl(evt.coordinate, viewResolution, this.projection, { 'INFO_FORMAT': 'text/html' });
                if (url) {
                    // const response = await fetch(url, { headers: { "Authorization": `Basic ${this.token}` } })
                    const response = await fetch(url); //, { headers: { "Authorization": `Basic ${this.token}` } })
                    this.popupContent += await response.text();
                }
            }
        }
        this.popupContent += `</div>`;
        this.popup.setPosition(coordinate);
    };

    onClosePopup() {
        this.popup.setPosition(undefined)
        this.popupContent = null;
    }

    loadingFeatures: boolean = false;
    features: geoserver.feature<wfsDataLayer.properties, wfsDataLayer.geometry>[] = [];
    onChangeExtent = debounce((that) => this.reloadLayerData(that), 1000)
    async reloadLayerData(that) {
        that.features = [];
        const extent = that.map.getView().calculateExtent()
        const wfsLayer = settings.wfsLayer;

        let url = `${settings.geoServerUrl}/wfs?request=GetFeature&typename=${wfsLayer.workspace}:${wfsLayer.layer}&outputFormat=application/json`;
        url += '&returnGeometry=false'
        url += `&srsname=${that.projection}`;
        url += `&bbox=${extent.join(',')},${that.projection}`;

        that.loadingFeatures = true;
        const r = await geoServerService.featureServiceLayerData(url, that.token);
        that.loadingFeatures = false;
        that.features = r?.features ?? [];
    }


    generateTileLayerWMS(wmsLayer: layer.config, zIndex: number, style?: string, isVisibleByDesign: boolean = false): any {
        const _params: geoserver.params = { WORKSPACE: wmsLayer.workspace, LAYERS: wmsLayer.layer, VERSION: wmsLayer.version, TOKEN: null, STYLES: style };

        const wmsLayerSource = new TileWMS({
            attributions: wmsLayer.metadata.title,
            url: settings.geoServerUrl,
            params: _params,
            serverType: 'geoserver',
            tileLoadFunction: (tile: any, src) => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.open('GET', src);
                xhr.setRequestHeader("Access-Control-Allow-Origin", `*`);
                xhr.onload = function () {
                    if (this.response) {
                        const objectUrl = URL.createObjectURL(xhr.response);
                        const img = tile.getImage();
                        img.onload = () => {
                            URL.revokeObjectURL(objectUrl);
                        };
                        img.src = objectUrl;
                    } else {
                        tile.setState(3);
                    }
                };
                xhr.onerror = function () {
                    tile.setState(3);
                };
                xhr.send();
            }
        });

        const tileLayer = new TileLayer({
            visible: isVisibleByDesign || (this.layerId && wmsLayer.layer === this.layerId),
            source: wmsLayerSource,
            zIndex: zIndex
        });

        tileLayer.on('change:visible', this.toggleVisibleLayer);

        return tileLayer;
    }
}

