import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "ol/ol.css";

Vue.config.productionTip = false

import "@/assets/less/style.less";

import * as components from '@/components';
// Register global components functions
Object.keys(components).forEach(key => {
  Vue.component(key, components[key])
});


import settings, { loadSettings } from './settings'
Vue.config.productionTip = false

// import { authService } from './services/authService';
import msal, { msalMixin } from 'vue-msal'
loadSettings().then(async () => {
  // await authService.isAuthorized();

  Vue.use(msal, {
    auth: {
      clientId: settings.auth.clientId,
      authority: settings.auth.authority,
      redirectUri: `${window.location.protocol}//${window.location.host}/auth`,
      postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}/logout`,
    }
  });
  Vue.mixin(msalMixin);

  new Vue({
    router,
    store,
    mixins: [msalMixin],
    render: h => h(App),
  }).$mount('#app')
})