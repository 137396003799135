import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Index from '../views/MapHome/index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/:layerId?',
    name: 'home',
    component: Index,
    props: true,
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
