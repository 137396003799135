export function formatWeight(value: any, c?: number, d?: string, t?: string) {
    if (c === undefined) return null;

    var c = isNaN((c = Math.abs(c))) ? 2 : c,
        d = d == undefined || d == null ? "," : d,
        t = t == undefined || d == null ? "˙" : t,
        s = value < 0 ? "-" : "";

    var parsedNumber = parseFloat(Math.abs(Number(value) || 0).toFixed(c));
    if (parsedNumber <= 0)
        c = 0; //azzero i decimali nel caso in cui il valore sia 0

    var i = String(parseInt((value = Math.abs(Number(value) || 0).toFixed(c)))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return (s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(value - Number(i)).toFixed(c).slice(2) : ""));
}