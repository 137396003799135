import { Vue, Component, Prop } from "vue-property-decorator";

import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";

import settings from "@/settings";
import { geoServerService } from "@/services/geoServerService";

@Component({})
export default class TocItem extends Vue {
    @Prop({ required: true })
    layer: TileLayer<TileWMS>

    @Prop({ required: true })
    layerIndex: number

    @Prop({ required: false, default: () => [] })
    splitedLayersIds: number[];

    @Prop({ default: null, required: false })
    tab: string

    get params() {
        return this.layer.getSource()?.getParams() as geoserver.params;
    }

    get styles(): { value: string, label: string }[] {
        return settings.wmsLayers.find(f => f.layer === this.params.LAYERS && f.workspace === this.params.WORKSPACE && f.version === this.params.VERSION)?.styles ?? [];
    }


    private privateStyleSelected: { value: string, label: string } = null;
    public get styleSelected(): { value: string, label: string } {
        return this.privateStyleSelected;
    }
    public set styleSelected(v: { value: string, label: string }) {
        if (v && v.value !== this.privateStyleSelected?.value) {
            this.privateStyleSelected = v;
            this.$emit('changeStyle', { params: this.params, style: this.privateStyleSelected })
            this.downloadLegend()
        }
    }


    private privateActiveFn: string = null;
    public get activeFn(): string {
        return this.privateActiveFn;
    }
    public set activeFn(v: string) {
        if (this.privateActiveFn === v) {
            this.privateActiveFn = null;
        } else {
            this.privateActiveFn = v;
        }
    }

    public get visible(): any {
        return this.layer.getVisible();
    }
    public set visible(value: any) {
        if (this.splitedLayersIds.includes(this.layerIndex)) {
            this.handleSwipe();
        }
        this.layer.setVisible(value);
    }

    public get opacity(): any {
        return this.layer.getOpacity();
    }
    public set opacity(v: any) {
        this.layer.setOpacity(parseFloat(v));
    }

    public get zIndex(): any {
        return this.layer.getZIndex();
    }
    public set zIndex(v: any) {
        this.layer.setZIndex(parseFloat(v));
    }

    get metadata(): layer.metadata {
        const emptyContent = { title: null, description: null, downloadTypes: [] };
        if (!this.params) return emptyContent
        return settings.wmsLayers.find(f => f.workspace === this.params.WORKSPACE && f.layer === this.params.LAYERS)?.metadata ?? emptyContent;
    }

    get swipePosition(): 'left' | 'right' | null {
        const idx = this.splitedLayersIds.indexOf(this.layerIndex);
        if (idx >= 0) {
            if (idx === 0) {
                return 'left';
            }
            return 'right';
        }
        return null;
    }

    get token(): string {
        return null;
        // return localStorage.getItem("auth_code");
    }

    legendUrl: string = null;
    async created() {
        this.styleSelected = this.styles && this.styles.length > 0 ? this.styles[0] : null;
        this.downloadLegend();
    }

    mounted() {
        this.activeFn = this.tab ? this.tab : 'description';
        if (this.activeFn === 'split-view' && this.visible) {
            setTimeout(this.handleSwipe, 1000)
        }
    }

    downloadLegend() {
        this.legendUrl = null;
        const props = { STYLE: this.styleSelected?.value, LAYER: this.params.LAYERS };
        const basicUrl = this.layer.getSource().getLegendUrl(null, props);
        this.$nextTick(async () => {
            this.legendUrl = await geoServerService.download(basicUrl, this.token);
        })
    }

    handleSwipe() {
        this.activeFn = 'split-view';
        this.$emit('split-view');
    }

    downloading: string = null;
    async handleDownload(layerType: 'WPS' | 'WMS' | 'WFS' | 'STYLE', downloadType: string, title: string, extension: string) {
        this.downloading = `${this.params.LAYERS}.${title}.${extension}`;
        let _url: string = null
        switch (layerType) {
            case 'WPS': {
                _url = await geoServerService.downloadPluginWPS(this.params, this.token, downloadType);
                break;
            }
            case 'WMS': {
                _url = await geoServerService.download(`${settings.geoServerUrl}/${this.params.WORKSPACE}/wms?service=WMS&version=${this.params.VERSION}&token=${this.token}&request=GetMap&layers=${this.params.WORKSPACE}%3A${this.params.LAYERS}&bbox=467245.90705731564%2C4995519.400555587%2C556694.4257545553%2C5068709.35333587&width=768&height=628&srs=EPSG%3A7791&styles=&format=${downloadType}`, this.token);
                break;
            }
            case 'WFS': {
                _url = await geoServerService.download(`${settings.geoServerUrl}/${this.params.WORKSPACE}/ows?service=WFS&version=${this.params.VERSION}&token=${this.token}&request=GetFeature&typeName=${this.params.WORKSPACE}%3A${this.params.LAYERS}&outputFormat=${downloadType}`, this.token);
                break;
            }
            case 'STYLE': {
                _url = await geoServerService.download(`/assets/geo-styles/${this.params.LAYERS}.zip`, this.token);
                break;
            }
            case 'STYLE': {
                _url = await geoServerService.download(`/assets/geo-styles/${this.params.LAYERS}.zip`, this.token);
                break;
            }
        }

        if (!!_url) {
            var a = document.createElement("a") as any;
            document.body.appendChild(a);
            a.style = "display: none; visibility: hidden;";
            a.href = _url;
            a.download = this.downloading;
            a.click();
            this.$nextTick(() => {
                document.body.removeChild(a);
            })
        }

        this.downloading = null;
    }
}