import axios from 'axios';
import * as echarts from 'echarts';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ResizeMixin from '@/mixins/resize'

import { mergeDeep } from '@/utils/mergeDeep';
import { formatWeight } from '@/utils/formatter';
@Component
export default class BarChart extends mixins(ResizeMixin) {
    @Prop({required: true}) 
    private options: echarts.EChartsOption;
    @Prop({ default: 'main-chart-container' }) private className!: string;
    @Prop({ default: 'barChart' }) private prefixId!: string;
    @Prop({ default: '100%' }) private width!: string;
    @Prop({ default: '100%' }) private height!: string;

    get id(){
        return `${this.prefixId}__${Math.random()*10000000}`
    }

    async mounted() {
        const _xAxisMerge = mergeDeep({
            type: "category",
            min: null,
            axisLabel: {
                show: true,
                formatter: (value) => {
                    return `${value ?? ""}`;
                }
            },
            axisLine: {
                lineStyle: {
                    color: '#999'
                }
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#eee',
                    type: 'dashed'
                }
            },
            axisTick: {
                show: false
            }
        } as echarts.XAXisComponentOption, this.options.xAxis);

        const _yAxisMerge = mergeDeep({
            type: "value",
            min: null,
            axisLabel: {
                show: true,
                formatter: (value) => {
                    return `${!!value ? formatWeight(value, 0) : ""}`;
                }
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#eee',
                    type: 'dashed'
                }
            }
        } as echarts.YAXisComponentOption, this.options.yAxis);

        const options = {
            xAxis: _xAxisMerge,
            yAxis: _yAxisMerge,

            // Chart animation duration
            animationDuration: 750,

            // Setup grid
            grid: {
                left: "10px",
                right: "10px",
                top: "35px",
                bottom: "0px",
                containLabel: true
            },

            // Add tooltip
            tooltip: {
                position: 'inside',
                trigger: 'item',
                backgroundColor: 'rgba(255,255,255,0.9)',
                padding: [10, 15],
                axisPointer: { // Use axis to trigger tooltip
                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                },
                confine: true,
                textStyle: {
                    fontSize: 12,
                    fontFamily: 'Lato, sans-serif'
                },
                valueFormatter: (value: number) => {
                    return `${!!value ? formatWeight(value, 0) : ""}`;
                }
            },
            backgroundColor: this.options.backgroundColor ?? '#fff',
            animationEasing: 'elasticOut',
            animationEasingUpdate: 'elasticOut',
            animationDelay: (idx: number) => { return idx * 20; },
            animationDelayUpdate: (idx: number) => { return idx * 20; },
            series: [] as any[]
        } as echarts.EChartsOption;
        
        mergeDeep(options, this.options);

        this.$nextTick(() => {
            this.initChart(options);
        });
    }

    beforeDestroy() {
        if (!this.chart) {
            return;
        }
        this.chart.dispose();
        this.chart = null;
    }

    private initChart(options: echarts.EChartsOption) {
        this.chart = echarts.init(document.getElementById(this.id) as HTMLDivElement, null, {
            renderer: 'svg',
            useDirtyRect: false
        });
        this.chart.setOption(options);
    }
}