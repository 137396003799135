import { Vue, Component, Prop } from "vue-property-decorator";

import { authService } from "@/services/authService";

import MainHeader from "./components/main-header/main-header.vue";
import Modal from "@/components/modal/modal.vue";
import MainMap from "./components/maps/maps.vue"

@Component({ components: { MainHeader, Modal, MainMap } })
export default class MapHome extends Vue {
    @Prop({ default: null, required: false })
    layerId?: string

    // @Prop({ default: null, required: false })
    // tab?: string

    get tab(){
        return this.$route?.query?.tab ?? 'description';
    }

    // private privateToken: string = localStorage.getItem("auth_code");
    // get token(): string {
    //     return this.privateToken
    // }
    // public set token(v: string) {
    //     this.privateToken = v;
    // }

    // authenticating: boolean = false;
    // credential: { username: string, password: string, error: boolean } = { username: null, password: null, error: null }
    // async login() {
    //     this.authenticating = true;
    //     this.token = await authService.login(this.credential.username, this.credential.password);
    //     this.authenticating = false;
    //     this.credential.error = !this.token;
    // }

    get user() {
        let user = null;
        if (this.$msal.isAuthenticated()) {
            user = {
                ...this.$msal.data.user,
                profile: {}
            }
            if (this.$msal.data.graph && this.$msal.data.graph.profile) {
                user.profile = this.$msal.graph.data.profile
            }
        }
        return user;
    }
}