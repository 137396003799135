import axios from "axios";

declare const PRODUCTION;

interface ISettings {
  geoServerUrl: string
  geoServerCredentialCheckUrl: string
  product: { title: string, description: string, metadata: string, tableDates: string }
  wmsLayers: layer.config[]
  wfsLayer: layer.config,
  wpsPlugin: {
    serviceUrl: string,
    xmlConfig: string
  },
  auth:{
    clientId: string
    authority: string
  }
}


// You can customize settings below :)
const settings: ISettings = {
  geoServerUrl: 'https://geoserver.esairide.tddev.it/geoserver',
  geoServerCredentialCheckUrl: `https://geoserver.esairide.tddev.it/geoserver/IT002_MILANO/IRIDE-S_S4-06-01_2023_V0/ows?SERVICE=WMS&REQUEST=GetCapabilities`,
  product: {
    title: "SE-S4-06 Green urban areas characterization",
    description: "The SVC Green urban areas characterization will provide the first national-scale observatory of green urban areas focusing on the tree height estimation, for cities exceeding 50,000 inhabitants, to support the national and local authorities in managing Green Urban Areas. The service will be able to monitor the extension of green areas within the FUA (Functional Urban Area) of cities on an annual basis, providing an innovative characterization of trees based on the average of their height. The service obviates the necessity for expensive approaches such as LiDAR or field measurements, thereby setting a new standard in urban forestry analytics. Furthermore, topographic products such slope, aspect and curvature are retrieved from the green urban areas, amplifying the product's utility in land-use planning and environmental monitoring. Finally, demographic and physical statistics, useful for understanding the urban environment and for supporting planning decisions, are provided in a vector product. The area of interest of the product in the Precursor phase is the FUA of Milan derived from CLMS - Urban Atlas input (IN-S4-06-05).",
    tableDates: "<table class=\"table-dates\"><tr><th>Layer</th><th>Source Name</th><th class=\"right\">Reference Time</th></tr><tr><td>S4-06-01 </td><td>Sentinel-2 - L2A all images</td><td class=\"right\">from 01-07-2023 to 31-07-2023</td></tr><tr><td>S4-06-02</td><td> Sentinel-1 - IW GRD </td><td class=\"right\">07-2023 last image</td></tr><tr><td>S4-06-04</td><td> High-resolution Italy DEM (Tinitaly DEM)</td><td class=\"right\">2023</td></tr><tr><td>S4-06-05</td><td> CLMS - Urban Atlas</td><td class=\"right\">2018</td></tr><tr><td>S4-06-06</td><td> ISTAT census data</td><td class=\"right\">2021</td></tr></table>",
    metadata: "https://dev-portal.irideservices.earth/geonetwork/srv/eng/catalog.search#/search?query_string=%7B%22tag.default%22:%7B%22SVC:S4-06%22:true%7D%7D"
  },
  wmsLayers: [
    {
    workspace: "IT002_MILANO",
    layer: 'IRIDE-S_S4-06-01_2023_V0',
    version: '1.3.0',
    metadata: {
      title: 'Urban and suburban green areas S4-06-01',
      description: 'Identification at 10mt spatial resolution of urban and sub-urban green areas. Classification is performed on two classes (1:"No Green"; 2:"Green") using Sentinel-2 (IN-S4-06-01) input images from July 2023 and Normalized Difference Vegetation Index (NDVI).',
      downloadTypes: ['WMS']
    }
  }, {
    workspace: "IT002_MILANO",
    layer: 'IRIDE-S_S4-06-02_2023_V0',
    version: '1.3.0',
    metadata: {
      title: 'Estimate average height of trees S4-06-02',
      description: 'Estimation at 20mt spatial resolution of the vegetation height averaged over the pixel surface area, in urban and sub-urban context. Classification is performed on the areas covered by vegetation (detected with OU-S4-06-01 product) in 4 different classes of height intervals (0:"0-3mt"; 1:"3-8mt"; 2:"8-15mt"; 3:">15mt"), using Sentinel-2 (IN-S4-06-01) and Sentinel-1 (IN-S4-06-02) as input data of a Machine Learning algorithm.',
      downloadTypes: ['WMS']
    }
  }, {
    workspace: "IT002_MILANO",
    layer: 'IRIDE-S_S4-06-03_2023_V0',
    version: '1.3.0',
    metadata: {
      title: 'Slope of the Green Urban Areas S4-06-03',
      description: 'Slope at 10mt spatial resolution of urban and sub-urban green areas (detected with OU-S4-06-01 product), using High-resolution Italy DEM (IN-S4-06-04) as input (Tinitaly). Values are given in degrees for slopes greater than 3°.',
      downloadTypes: ['WMS']
    }
  }, {
    workspace: "IT002_MILANO",
    layer: 'IRIDE-S_S4-06-04_2023_V0',
    version: '1.3.0',
    metadata: {
      title: 'Aspect of the Green Urban Areas S4-06-04',
      description: 'Aspect at 10mt spatial resolution of urban and sub-urban green areas using High-resolution Italy DEM (IN-S4-06-04) as input (Tinitaly). The values are provided in degrees to the north for slope degree values greater than 3° (detected with OU-S4-06-03 product).',
      downloadTypes: ['WMS']
    }
  }, {
    workspace: "IT002_MILANO",
    layer: 'IRIDE-S_S4-06-05_2023_V0',
    version: '1.3.0',
    metadata: {
      title: 'Curvature of Green Urban Areas S4-06-05',
      description: 'Profile curvature at 10mt resolution of the urban and sub-urban green areas using High-resolution Italy DEM (IN-S4-06-04) as input (Tinitaly). The product classifies the values of the curvature parallel (profile) to the direction of the maximum slope in 3 different classes (-1:"Convex"; 0:"Plain"; 1:"Concave"), basing on (+/-) 0.3 thesholds to define the Plain class, and is given for slope degree values greater than 3° (detected with OU-S4-06-03 product).',
      downloadTypes: ['WMS']
    }
  }, {
    workspace: "IT002_MILANO",
    layer: 'IRIDE-S_S4-06-06_2023_V0',
    version: '1.3.0',
    metadata: {
      title: 'Green Urban areas statistics S4-06-06 ',
      description: 'Vector spatial data of the urban and sub-urban context with statistics about physics and demographics indicators. Statistics are computed from raster products (OU-S4-06-01, OU-S4-06-02, OU-S4-06-03, OU-S4-06-04, OU-S4-06-05) and ISTAT census data (IN-S4-06-06).',
      tableUnitMeasures: "<table class=\"table-unit-measures\"><tr><th>Indicator</th><th>UoM</th><th>Description</th></tr><tr><td>population</td><td>num</td><td>Population</td></tr><tr><td>children</td><td>num</td><td>Childrens</td></tr><tr><td>elderly</td><td>num</td><td>Elderlies</td></tr><tr><td>Shape_Area</td><td>m<sup>2</sup></td><td>Shape Area (m<sup>2</sup>)</td></tr><tr><td>g_density</td><td>%</td><td>Urban Green density</td></tr><tr><td>g_on_nog</td><td>m<sup>2</sup>/m<sup>2</sup></td><td>Green surface on Non-Green surface</td></tr><tr><td>g_pop</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Urban Green areas per capita</td></tr><tr><td>g_children</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Urban Green areas per child</td></tr><tr><td>g_elderly</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Urban Green areas per elderly</td></tr><tr><td>t_pop</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Wooded Urban areas per capita</td></tr><tr><td>t_children</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Wooded Urban areas per child</td></tr><tr><td>t_elderly</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Wooded Urban areas per elderly</td></tr><tr><td>slo_mean</td><td>°</td><td>Mean of Slope</td></tr><tr><td>slo_stddev</td><td>°</td><td>Standard Deviation of Slope</td></tr><tr><td>slo_min</td><td>°</td><td>Minimum Slope</td></tr><tr><td>slo_max</td><td>°</td><td>Maximum Slope</td></tr><tr><td>slo_mode</td><td>°</td><td>Slope Mode</td></tr><tr><td>asp_mean</td><td>°</td><td>Mean of Aspect</td></tr><tr><td>asp_stddev</td><td>°</td><td>Standard Deviation of Aspect</td></tr><tr><td>asp_mode</td><td>°</td><td>Aspect Mode</td></tr><tr><td>curv_mode</td><td></td><td>Profile Curvature Mode</td></tr></table>",
      downloadTypes: ['WMS', 'WFS']
    }
  }, {
    workspace: "IT002_MILANO",
    layer: "S2_EX_IT002_MILANO_2023",
    version: '1.3.0',
    metadata: {
      title: 'Sentinel-2 sample input ',
      description: 'Example of Sentinel-2 L2A images used for product generation. The raster shows the T32TMR and T32TNR tails mosaic of the TCI (True Colour Images) product for July 20, 2023.',
      hideLegend: true,
      tocItemClass: "example",
      tableUnitMeasures: "",
      downloadTypes: []
    }
  }],
  wfsLayer: {
    workspace: "IT002_MILANO",
    layer: 'IRIDE_L03_S0406_O06',
    version: '1.3.0',
    metadata: {
      title: 'Green Urban areas statistics S4-06-06 ',
      description: 'Vector spatial data of the urban and sub-urban context with statistics about physics and demographics indicators. Statistics are computed from raster products (OU-S4-06-01, OU-S4-06-02, OU-S4-06-03, OU-S4-06-04, OU-S4-06-05) and ISTAT census data (IN-S4-06-06).',
      tableUnitMeasures: "<table class=\"table-unit-measures\"><tr><th>Indicator</th><th>UoM</th><th>Description</th></tr><tr><td>population</td><td>num</td><td>Population</td></tr><tr><td>children</td><td>num</td><td>Childrens</td></tr><tr><td>elderly</td><td>num</td><td>Elderlies</td></tr><tr><td>Shape_Area</td><td>m<sup>2</sup></td><td>Shape Area (m<sup>2</sup>)</td></tr><tr><td>g_density</td><td>%</td><td>Urban Green density</td></tr><tr><td>g_on_nog</td><td>m<sup>2</sup>/m<sup>2</sup></td><td>Green surface on Non-Green surface</td></tr><tr><td>g_pop</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Urban Green areas per capita</td></tr><tr><td>g_children</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Urban Green areas per child</td></tr><tr><td>g_elderly</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Urban Green areas per elderly</td></tr><tr><td>t_pop</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Wooded Urban areas per capita</td></tr><tr><td>t_children</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Wooded Urban areas per child</td></tr><tr><td>t_elderly</td><td>m<sup>2</sup></td><td>m<sup>2</sup> of Wooded Urban areas per elderly</td></tr><tr><td>slo_mean</td><td>°</td><td>Mean of Slope</td></tr><tr><td>slo_stddev</td><td>°</td><td>Standard Deviation of Slope</td></tr><tr><td>slo_min</td><td>°</td><td>Minimum Slope</td></tr><tr><td>slo_max</td><td>°</td><td>Maximum Slope</td></tr><tr><td>slo_mode</td><td>°</td><td>Slope Mode</td></tr><tr><td>asp_mean</td><td>°</td><td>Mean of Aspect</td></tr><tr><td>asp_stddev</td><td>°</td><td>Standard Deviation of Aspect</td></tr><tr><td>asp_mode</td><td>°</td><td>Aspect Mode</td></tr><tr><td>curv_mode</td><td></td><td>Profile Curvature Mode</td></tr></table>",
      downloadTypes: ['WMS']
    },
    styles: [
      { value: 'IRIDE-S_S4-06-06_2023_V0_g_density', label: 'g_density' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_g_on_nog', label: 'g_on_nog' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_g_pop', label: 'g_pop' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_g_children', label: 'g_children' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_g_elderly', label: 'g_elderly' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_t_children', label: 't_children' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_t_pop', label: 't_pop' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_t_elderly', label: 't_elderly' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_slo_mean', label: 'slo_mean' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_slo_stddev', label: 'slo_stddev' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_slo_min', label: 'slo_min' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_slo_max', label: 'slo_max' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_slo_mode', label: 'slo_mode' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_asp_mean', label: 'asp_mean' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_asp_stddev', label: 'asp_stddev' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_asp_mode', label: 'asp_mode' },
      { value: 'IRIDE-S_S4-06-06_2023_V0_curv_mode', label: 'curv_mode' }
    ]
  },
  wpsPlugin: {
    serviceUrl: "https://geoserver.esairide.tddev.it/geoserver/ows?service=WPS",
    xmlConfig: `<?xml version="1.0" encoding="UTF-8"?>
    <wps:Execute version="1.0.0" 
      service="WPS" 
      xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
      xmlns="http://www.opengis.net/wps/1.0.0" 
      xmlns:wfs="http://www.opengis.net/wfs" 
      xmlns:wps="http://www.opengis.net/wps/1.0.0" 
      xmlns:ows="http://www.opengis.net/ows/1.1" 
      xmlns:gml="http://www.opengis.net/gml" 
      xmlns:ogc="http://www.opengis.net/ogc" 
      xmlns:wcs="http://www.opengis.net/wcs/1.1.1" 
      xmlns:xlink="http://www.w3.org/1999/xlink" 
      xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
      <ows:Identifier>gs:Download</ows:Identifier>
      <wps:DataInputs>
      <wps:Input>
        <ows:Identifier>layerName</ows:Identifier>
        <wps:Data>
        <wps:LiteralData>{WORKSPACE}:{LAYERS}</wps:LiteralData>
        </wps:Data>
      </wps:Input>
      <wps:Input>
        <ows:Identifier>outputFormat</ows:Identifier>
        <wps:Data>
        <wps:LiteralData>{downloadType}</wps:LiteralData>
        </wps:Data>
      </wps:Input>
      </wps:DataInputs>
      <wps:ResponseForm>
      <wps:RawDataOutput mimeType="image/tiff">
        <ows:Identifier>result</ows:Identifier>
      </wps:RawDataOutput>
      </wps:ResponseForm>
   </wps:Execute>`
  },
  auth:{
    //esa iride planetek ambeinte di test per localhost:8499
    clientId: '55c23f0a-edfb-46e0-a8fa-c3a0ceb0e38e',
    authority: 'https://login.microsoftonline.com/dd991c28-366f-4c65-9e03-602cf1e7b13e'
  }
}


export async function loadSettings() {
  let response = null;
  try {
    response = await axios.get("./settings.custom.json");
    Object.assign(settings, response.data);
  } catch (e) {  console.error(e) }

  if (!response)
    try {
      response = await axios.get("./settings.json");
      Object.assign(settings, response.data);
    } catch (e) {  console.error(e) }

  try {
    if (PRODUCTION !== undefined && PRODUCTION) {
      console.error("NO CONFIGURATION FOUND !!", "please provide settings.json or settings.custom.json file !")
    }
  } catch { }
}



export default settings
